<template>
  <base-page
    :title="$t('templateManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="$api.companyFind"
      :multiple="false"
      :highlightCurrentRow="false"
      :columns="columns"
      :defaultParams="params"
      v-model="tableRow"
    >
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <!-- lang:模板分配 -->
        <el-button
            v-if="$page.showBtn('WORK_FLOW_STEP_TEMPLATE_ASSIGN_MANAGE_ASSIGN')"
            type="text"
            @click="assignMethod(row)"
        >
          {{ $t('functionEnum.WORK_FLOW_STEP_TEMPLATE_ASSIGN_MANAGE_ASSIGN') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    let _that = this
    return {
      // 搜索
      formData: {},
      // 显示表单
      showDetail:false,
      showDialogImport:false,
      // 表格页
      tableRow: null,
      tableRows: [],
      params: {
        companyUid: this.$store.getters.company.uid,
      },
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
      ],
      columns:  [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:企业名称
        {
          label: 'UID',
          prop: 'uid',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },

        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '200px',
        },
      ],
    }
  },
  methods: {
    // 关闭详情页回调
    closePage(cmd) {
      this.showDetail = false
      this.showRenewal = false
      this.search()
    },
     // 权限按钮点击回掉
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'import' && method !== 'add' && this.tableRows.length === 0) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        case 'export':
          this.exportMethod()
          break
        case 'import':
          this.showDialogImport = true
          break
        default: // do something
      }
    },
    async assignMethod(row) {
      this.tableRow = row
      this.$store.state.cmd = 'assign'
      this.showDetail = true
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
  },
}
</script>
